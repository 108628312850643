import React from "react"
import Layout from "../components/Layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import Banner from "../components/Banner/Banner"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import ArticleContainer from "../components/ArticleContainer/ArticleContainer"

import "../assets/css/post-template.css"
// import { FaFileExcel } from "react-icons/fa"
// import { HiOutlineDocumentAdd } from "react-icons/hi"
import { MdOutlineUpdate } from "react-icons/md"

const PostTemplate = ({ data }) => {
  const {
    mdx: {
      frontmatter: { title, category, image, date, embeddedImages },
      body,
    },
  } = data

  return (
    <Layout>
      <ArticleContainer>
        {/* post info */}
        <article className="article-wrap">
          <div className="article-main-img-wrapper">
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              className="article-main-img"
            />
          </div>

          <div className="article-info">
            <h3 className="article-title">{title}</h3>
            <div className="article-sub-info">
              <div className="article-category">
                {category.split(",").map((tag, index) => {
                  return (
                    <div key={index} className="article-category-text">
                      {tag}
                    </div>
                  )
                })}
              </div>
              <div className="article-date-wrapper">
                {/* <div className="article-date">
                  <MdDateRange className="article-date-icon" />
                  {date}
                </div> */}
                <div className="article-date">
                  <MdOutlineUpdate className="article-date-icon" />
                  {date}
                </div>
              </div>
            </div>
            <div className="underline"></div>
          </div>

          <div className="mdx-wrap">
            <MDXRenderer embeddedImages={embeddedImages}>{body}</MDXRenderer>
          </div>

          <div className="btn-to-blog-wrap">
            <Link to="/blog" className="btn-to-blog">
              全ての記事
            </Link>
          </div>
        </article>
      </ArticleContainer>
      {/* banner component */}
      {/* <article>
        <Banner />
      </article> */}
    </Layout>
  )
}

export const query = graphql`
  query GetSinglePost($slug: String) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        category
        date(formatString: "YYYY-MM-DD")
        readTime
        slug
        embeddedImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`

export default PostTemplate
